import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { login } from './../../services/handler';

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userName: '',
      password: '',
      token: localStorage.getItem('token'),
      inputErrors: {},
      loading: false
    }
  }

  _handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value
    }, this._validateFormInput(name, value));
  }

  _doLogin = (e) => {
    e.preventDefault();
    const {
      userName,
      password,
      inputErrors,
      loading,
    } = this.state;

    // Validate inputs
    this._validateFormInput('userName', userName);
    this._validateFormInput('password', password);

    if (_.isEmpty(inputErrors) && !loading) {
      const requestData = {
        userName: userName.trim(),
        password: password.trim()
      };

      this.setState({
        loading: true
      }, () => {
        // ~~~ Login ~~~
        login(requestData)
          .then(res => {
            if (res.data.success) {
              localStorage.setItem('token', res.data.token);
              localStorage.setItem('authUserInfo', JSON.stringify(res.data.authUserInfo));
              this.setState({
                token: localStorage.getItem('token')
              })
            } else {
              this.setState({
                loading: false
              })
              toast.error(res.data.error);
            }
          }
          ).catch(error => {
            this.setState({
              loading: false
            })
            toast.error(error.data.error);
          });
      })


    }
  }


  _validateFormInput = (field, value) => {
    const {
      inputErrors
    } = this.state;

    // Extra rules
    let displayName = '';
    let maxLength = null;

    if (field === 'userName') {
      displayName = 'User Name'
      maxLength = 100;
    } else if (field === 'password') {
      displayName = 'Password'
      maxLength = 16;
    }

    // * Required
    // * Max Length
    // * Regex
    if (['userName', 'password'].includes(field)) {
      if (value === '') {
        inputErrors[field] = { message: displayName + ' is required' }
      } else if (maxLength && (value.length > maxLength)) {
        inputErrors[field] = { message: displayName + ' can be at most ' + maxLength + ' characters' }
      } else {
        delete inputErrors[field]
      }
    }

    this.setState({
      inputErrors
    });
  }

  render() {

    const { from } = this.props.location.state || { from: { pathname: '/' } }

    const {
      userName,
      password,
      token,
      inputErrors,
      loading,
    } = this.state;

    if (token) {
      return <Redirect to={from} />
    }

    return (
      <div className="container">
        <div className="col-md-6 offset-md-3">
          <form className="bg-light p-5 contact-form" onSubmit={this._doLogin}>
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Email" name="userName" value={userName} onChange={this._handleInputChange} />
              {inputErrors.userName ? <p className="col-red">{inputErrors.userName.message}</p> : ''}
            </div>
            <div className="form-group">
              <input type="password" className="form-control" placeholder="Password" name="password" value={password} onChange={this._handleInputChange} />
              {inputErrors.password ? <p className="col-red">{inputErrors.password.message}</p> : ''}
            </div>
            <div className="form-group text-center">
              <button type="submit" className="btn btn-info py-3 px-5">{loading ? (<i className="fa fa-spinner fa-spin"></i>) : 'Log In'}</button>
            </div>
            {!loading &&
              <div className="form-group text-center">
                <Link to={'/signup'} className="col-black">Sign Up</Link>
              </div>
            }
          </form>
        </div>
      </div>
    )
  }
}

export default Login