import React, { Component } from 'react';
import {
  getCategory,
  addCategory,
  updateCategory,
  deleteCategory
} from './../../services/handler';

// Custom images
import WinkEmoji from '../../assets/images/Wink Emoji.png'
import iconEdit from '../../assets/images/edit.svg'
import iconDelete from '../../assets/images/delete.svg'

class ToDoCategory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      newItem: '',
      editingItem: ''
    }
  }

  componentWillMount() {
    // ~~~ Get Categories ~~~
    getCategory()
      .then(res => {
        this.setState({
          items: res.category
        });
      }
      ).catch(error => {
        console.log(error)
      });
  }

  componentDidMount() {
    this.newItemInput.focus();
  }

  componentDidUpdate() {
    if (this.state.editingItem !== '') {
      this.editingItemInputTitle.focus();
    }
  }

  _handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value
    });
  }

  _submitTodoCategory = (e) => {
    e.preventDefault();
    const {
      newItem,
      items
    } = this.state;

    if (newItem.trim() && this._findDuplicateItem(newItem, items)) {
      const newCategory = { name: newItem.trim(), status: 0 };
      // ~~~ Add ToDo ~~~
      addCategory(newCategory)
        .then(res => {
          items.push(res.newCategory);
          this.setState({
            items: items,
            newItem: ''
          });
        }
        ).catch(error => {
          console.log(error)
        });
    }
  }

  _findDuplicateItem = (item, data) => {
    let flag = true;
    data.forEach((value, index) => {
      if (value.title === item) {
        data[index].duplicate = true;
        this.setState({
          items: data
        });
        flag = false;
        return;
      }
    });
    return flag;
  }

  _deleteItem = (index) => {
    let items = this.state.items;   

    deleteCategory(items[index]._id)
      .then(res => {
        items.splice(index, 1);
        this.setState({
          items: items
        });
      }
      ).catch(error => {
        console.log(error)
      });
  }

  _editItem = (index) => {
    const editData = this.state.items;
    this.setState({
      editingItem: {
        index: index,
        data: editData[index]
      }
    });
  }

  _handleEditInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      editingItem: {
        ...this.state.editingItem,
        data: {
          ...this.state.editingItem.data,
          [name]: value
        },
      }
    });
  }

  _updateEditingItem = (index) => {
    let updateItem = this.state.editingItem.data;
    // ~~~ Update Category ~~~
    updateCategory(updateItem, updateItem._id)
      .then(res => {
        const items = this.state.items;
        items[index] = res.updatedItem;
        this.setState({
          items,
          editingItem: ''
        });
      }
      ).catch(error => {
        console.log(error)
      });
  }

  _cancelEditingItem = (index) => {
    this.setState({
      editingItem: ''
    });
  }

  render() {

    const {
      items,
      newItem,
      editingItem
    } = this.state;

    return (
      <div id="colorlib-main">
        <section className="ftco-section">
          <div className="container">
            <form onSubmit={this._submitTodoCategory}>
              <div className="input-group">
                <input type="text" ref={(input) => { this.newItemInput = input; }} name="newItem" className="form-control" placeholder="Enter Category name" value={newItem} onChange={this._handleInputChange} />
                <input type="submit" className="btn btn-success remove-border-radius btn-add-new-item" value="Add" disabled={newItem === '' ? true : false} />
              </div>
            </form>

            <br />
            {items.length ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((value, index) => {
                    return (
                      <tr key={index} className={`tableRowHoverHandPointer ${value.status === 1 ? 'toDoItemDoneBgColor' : 'toDoItemBgColor'}`}>
                        <td>
                          {index + 1}
                        </td>
                        <td className="txtColorBlack">
                          <span>{value.name}</span>
                          {editingItem !== '' && editingItem.index === index &&
                            <div className="card remove-border-radius">
                              <div className="card-body">
                                <div className="form-group">
                                  <input type="text" name="name" className="form-control" ref={(input) => { this.editingItemInputTitle = input; }} value={editingItem.data.name} onChange={this._handleEditInputChange} />
                                </div>
                                <button className="btn btn-info remove-border-radius mr5" onClick={() => this._updateEditingItem(index)}>Update</button>
                                <button className="btn btn-default remove-border-radius" onClick={() => this._cancelEditingItem(index)}>Cancel</button>
                              </div>
                            </div>
                          }
                        </td>
                        <td className="actionTD">
                          <img src={iconEdit} className="editItemIcon mr5" onClick={() => this._editItem(index)} alt={'Edit'} />
                          <img src={iconDelete} className="deleteItemIcon" onClick={() => this._deleteItem(index)} alt={'Delete'} />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            ) : (
                <div className="kt-text-align-center">
                  <h5><em>No data found !</em></h5>
                  <img src={WinkEmoji} className="kt-width-25-percent" alt="Wink Emoji" />
                </div>
              )
            }
          </div>
        </section>
      </div>
    )
  }
}

export default ToDoCategory