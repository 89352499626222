// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route {...rest} render={(props) => (
//     false === true
//       ? <Component {...props} />
//       : <Redirect to={{
//           pathname: '/login',
//           state: { from: props.location }
//         }} />
//   )} />  
// )

// OR

import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

class AdminRoute extends Component {

  constructor(props) {
    super(props);
    this.state = {
      adminToken : localStorage.getItem('adminToken')
    }
  }

  render() {    
    return (
      <Route {...this.rest} render={(props) => (
        this.state.adminToken
          ? (
              <this.props.component {...props} />
          )
          : (
            <Redirect to={{
              pathname: '/login-as-admin',
              state: { from: props.location }
            }} />
          )
      )} />  
    )
  }
}


export default AdminRoute