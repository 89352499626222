import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

class Menu extends Component {

  constructor(props) {
    super(props);

    const { pathname } = this.props.location

    this.state = {
      activeMenu: pathname,
      logout: false
    }
  }

  _handleMenuClick = (menuName) => {
    this.setState({
      activeMenu: menuName,
    })
  }

  _logout = () => {
    localStorage.removeItem('token')
    this.setState({
      logout: true
    })
  }

  render() {

    const {
      activeMenu,
      logout
    } = this.state;

    if (logout === true) {
      return <Redirect to={'/login'} />
    }

    const user = JSON.parse(localStorage.getItem('authUserInfo'))

    return (
      <React.Fragment>
        <aside id="colorlib-aside" role="complementary" className="js-fullheight text-center">
          <nav id="colorlib-main-menu" role="navigation">
            <ul>
              <li className={activeMenu === '/' ? "colorlib-active" : ''} onClick={() => this._handleMenuClick('/')}>
                <Link to={'/'}>Dashboard</Link>
              </li>
              <li className={activeMenu === '/work-clock' ? "colorlib-active" : ''} onClick={() => this._handleMenuClick('/work-clock')}>
                <Link to={'/work-clock'}>Work Clock</Link>
              </li>
              <li className={activeMenu === '/to-do' ? "colorlib-active" : ''} onClick={() => this._handleMenuClick('/to-do')}>
                <Link to={'/to-do'}>To Do</Link>
              </li>
              <li className={activeMenu === '/settings' ? "colorlib-active" : ''} onClick={() => this._handleMenuClick('/settings')}>
                <Link to={'/settings'}>Settings</Link>
              </li>
              <li onClick={this._logout}>
                <span className="logoutText">Logout</span>
              </li>
              {user.admin && (
                <Fragment>
                  <hr />
                  <li className={activeMenu === '/admin' ? "colorlib-active" : ''} onClick={() => this._handleMenuClick('/admin')}>
                    <Link to={'/admin'}>Admin</Link>
                  </li>
                  <hr />
                </Fragment>
              )}
            </ul>
          </nav>
          <div className="colorlib-footer">
            <hr />
            <h6 className="">Crafted by</h6>
            <div className="d-flex justify-content-center">
              <h3 className="signature h1">Ketan Trentiya</h3>
              {/* <form action="#" className="colorlib-subscribe-form">
              <div className="form-group d-flex">
                <div className="icon"><span className="icon-paper-plane" /></div>
                <input type="text" className="form-control" placeholder="Enter Email Address" />
              </div>
            </form> */}
            </div>
          </div>
        </aside>
        {this.props.children}
      </React.Fragment>
    )
  }
}

export default withRouter(Menu)