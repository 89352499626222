import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class NotFoundPage extends Component {

  handleBack = () => {
    this.props.history.goBack();    
  }
  
  render() {   
    return (
      <div id="colorlib-main">
        <section className="ftco-section">
          <div className="container">
            <h1>404 - Page not found</h1>
            <button className="btn btn-outline-info remove-border-radius" onClick={this.handleBack}>Go Back</button>  
          </div>
        </section>
      </div>
    )
  }
}

export default withRouter(NotFoundPage)