import React, { Component } from 'react';
import WorkedTime from './WorkedTime';
import Menu from '../LeftPanel'

class Home extends Component {
	constructor() {
		super();
		this.state = {
			workTime: {
				hours: 8,
				minutes: 30,
			},
			signIn: {
				hours: '',
				minutes: '',
			},
			goToHome: {
				hours: 0,
				minutes: 0,
			},
			outIn: [],
			WorkedTimeCompState: null
		}

		this.fnWorkTimeHours = this.fnWorkTimeHours.bind(this);
		this.fnWorkTimeMinutes = this.fnWorkTimeMinutes.bind(this);
		this.fnSignTimeHours = this.fnSignTimeHours.bind(this);
		this.fnSignTimeMinutes = this.fnSignTimeMinutes.bind(this);
		this.fnOutIn = this.fnOutIn.bind(this);
		this.calculateOutInLogs = this.calculateOutInLogs.bind(this);
		this.integerOnly = this.integerOnly.bind(this);
		this.basicInputs = this.basicInputs.bind(this);
	}

	componentDidMount(){
		this._getWorkClockData();
	}

	integerOnly(value) {
		var regInteger = /^\d+$/;
		if (!regInteger.test(value)) {
			return false;
		}
		else {
			return true;
		}
	}

	fnWorkTimeHours(event) {
		if (event.target.value > 12 || event.target.value.length > 2) {
			return false;
		}
		else if (event.target.value != '' && !this.integerOnly(event.target.value)) {
			return false;
		}
		var state = Object.assign({}, this.state.workTime);
		state['hours'] = event.target.value;
		this.setState({ workTime: state });
	}

	fnWorkTimeMinutes(event) {
		if (event.target.value > 59 || event.target.value.length > 2) {
			return false;
		}
		else if (event.target.value != '' && !this.integerOnly(event.target.value)) {
			return false;
		}
		var state = Object.assign({}, this.state.workTime);
		state['minutes'] = event.target.value;
		this.setState({ workTime: state });
	}

	fnSignTimeHours(event) {
		if (event.target.value > 12 || event.target.value.length > 2) {
			return false;
		}
		else if (event.target.value != '' && !this.integerOnly(event.target.value)) {
			return false;
		}
		var state = Object.assign({}, this.state.signIn);
		state['hours'] = event.target.value;
		this.setState({ signIn: state });
	}

	fnSignTimeMinutes(event) {
		if (event.target.value > 59 || event.target.value.length > 2) {
			return false;
		}
		else if (event.target.value != '' && !this.integerOnly(event.target.value)) {
			return false;
		}
		var state = Object.assign({}, this.state.signIn);
		state['minutes'] = event.target.value;
		this.setState({ signIn: state });
	}

	fnOutIn(event) {
		this.setState({ outIn: [...this.state.outIn, { out: { hours: '', minutes: '' }, in: { hours: '', minutes: '' } }] });
	}

	handleEntryLog(event, i, item, tFormat) {
		if (event.target.value != '' && !this.integerOnly(event.target.value)) {
			return false;
		}
		var state = Object.assign([], this.state.outIn);
		if (item == 'out') {
			if (tFormat == 'hours') {
				if (event.target.value == '') {
					state[i] = { out: { hours: '', minutes: this.state.outIn[i]['out']['minutes'] }, in: { hours: this.state.outIn[i]['in']['hours'], minutes: this.state.outIn[i]['in']['minutes'] } };
				}
				else if (event.target.value > 12) {
					return false;
				}
				else {
					state[i] = { out: { hours: parseInt(event.target.value), minutes: this.state.outIn[i]['out']['minutes'] }, in: { hours: this.state.outIn[i]['in']['hours'], minutes: this.state.outIn[i]['in']['minutes'] } };
				}
			}
			else if (tFormat == 'minutes') {
				if (event.target.value == '') {
					state[i] = { out: { hours: this.state.outIn[i]['out']['hours'], minutes: '' }, in: { hours: this.state.outIn[i]['in']['hours'], minutes: this.state.outIn[i]['in']['minutes'] } };
				}
				else if (event.target.value > 59) {
					return false;
				}
				else {
					state[i] = { out: { hours: this.state.outIn[i]['out']['hours'], minutes: parseInt(event.target.value) }, in: { hours: this.state.outIn[i]['in']['hours'], minutes: this.state.outIn[i]['in']['minutes'] } };
				}
			}
		}
		else if (item == 'in') {
			if (tFormat == 'hours') {
				if (event.target.value == '') {
					state[i] = { out: { hours: this.state.outIn[i]['out']['hours'], minutes: this.state.outIn[i]['out']['minutes'] }, in: { hours: '', minutes: this.state.outIn[i]['in']['minutes'] } };
				}
				else if (event.target.value > 12) {
					return false;
				}
				else {
					state[i] = { out: { hours: this.state.outIn[i]['out']['hours'], minutes: this.state.outIn[i]['out']['minutes'] }, in: { hours: parseInt(event.target.value), minutes: this.state.outIn[i]['in']['minutes'] } };
				}
			}
			else if (tFormat == 'minutes') {
				if (event.target.value == '') {
					state[i] = { out: { hours: this.state.outIn[i]['out']['hours'], minutes: this.state.outIn[i]['out']['minutes'] }, in: { hours: this.state.outIn[i]['in']['hours'], minutes: '' } };
				}
				else if (event.target.value > 59) {
					return false;
				}
				else {
					state[i] = { out: { hours: this.state.outIn[i]['out']['hours'], minutes: this.state.outIn[i]['out']['minutes'] }, in: { hours: this.state.outIn[i]['in']['hours'], minutes: parseInt(event.target.value) } };
				}
			}
		}
		this.setState({ outIn: state });
	}

	calculateOutInLogs() {
		var tWTMinutes = parseInt(this.state.workTime.hours * 60) + parseInt(this.state.workTime.minutes);
		var tSignInMinutes = parseInt(this.state.signIn.hours * 60) + parseInt(this.state.signIn.minutes);
		var xHours = parseInt(((parseInt(tSignInMinutes) + parseInt(tWTMinutes)) / 60) % 12);
		var xMinutes = (parseInt(tSignInMinutes) + parseInt(tWTMinutes)) % 60;
		this.state.outIn.map((obj, i) => {
			if (obj.out.hours.toString().length && obj.out.minutes.toString().length && obj.in.hours.toString().length && obj.in.minutes.toString().length) {
				let outHours = obj.out.hours;
				let inHours = obj.in.hours;
				if (obj.out.hours > obj.in.hours) {
					inHours = inHours + 12;
				}
				let outMinutes = (parseInt(outHours) * 60) + (parseInt(obj.out.minutes));
				let inMinutes = (parseInt(inHours) * 60) + (parseInt(obj.in.minutes));
				let x = parseInt((inMinutes - outMinutes) / 60);
				let y = parseInt((inMinutes - outMinutes) % 60);
				xHours = xHours + x;
				xMinutes = xMinutes + y;
				if (xMinutes >= 60) {
					xHours = xHours + parseInt(xMinutes / 60);
					xMinutes = parseInt(xMinutes % 60);
				}
			}
		});

		// this.setState({goToHome : {hours:xHours,minutes:xMinutes}});

		return { hours: xHours, minutes: xMinutes };
	}

	handleRemoveLog(event, i) {
		this.state.outIn.splice(i, 1);
		this.setState({
			outIn: this.state.outIn,
		});
		this.calculateOutInLogs();
		localStorage.setItem('outIn', JSON.stringify(this.state.outIn));
	}

	basicInputs() {
		if (!this.state.workTime.hours.toString().length || !this.state.workTime.minutes.toString().length || !this.state.signIn.hours.toString().length || !this.state.signIn.minutes.toString().length) {
			return true;
		}
		for (let value of this.state.outIn) {
			if (!value.out.hours.toString().length || !value.out.minutes.toString().length || !value.in.hours.toString().length || !value.in.minutes.toString().length) {
				return true;
			}
		}
		return false;
	}

	_save = () => {
		localStorage.setItem('workTime', JSON.stringify(this.state.workTime));
		localStorage.setItem('signIn', JSON.stringify(this.state.signIn));
		localStorage.setItem('outIn', JSON.stringify(this.state.outIn));
	}

	_getWorkClockData = () => {
		const workTime = JSON.parse(localStorage.getItem('workTime'));
		const signIn = JSON.parse(localStorage.getItem('signIn'));
		const outIn = JSON.parse(localStorage.getItem('outIn'));
		if(workTime){
			this.setState({
				workTime,
				signIn,
				outIn,
			});
		}
	}

	_newUpdate = () => {
		const {
			workTime,
			signIn,
			outIn
		} = this.state;
		if(localStorage.getItem('workTime') !== JSON.stringify(workTime))
		{
			return true;
		}
		if(localStorage.getItem('signIn') !== JSON.stringify(signIn))
		{
			return true;
		}
		if(localStorage.getItem('outIn') !== JSON.stringify(outIn))
		{
			return true;
		}
		return false;
	}

	render() {
		const goToHomeHM = this.calculateOutInLogs();

		return (
			<React.Fragment>
      <Menu>
			<div id="colorlib-main">
				<section className="ftco-section">
					<div className="container">

						{this._newUpdate() && <div className='row'>
							<div className='col-md-6'>
							<div className='input-group'>
									<button className='btn btn-success remove-border-radius' onClick={() => this._save()}><i className="fa fa-floppy-o" aria-hidden="true"></i></button>
								</div>
							</div>
						</div>}

						<br/>
						<div className='row'>
							<div className='col-md-6'>
								<label>Work Time</label>
								<div className='input-group'>
									<input type='text' className='form-control' placeholder='Hours' value={this.state.workTime.hours} onChange={this.fnWorkTimeHours} />
									<input type='text' className='form-control' placeholder='Minutes' value={this.state.workTime.minutes} onChange={this.fnWorkTimeMinutes} />
								</div>
								<br />
								<label>Sign In</label>
								<div className='input-group'>
									<input type='text' className='form-control' placeholder='Hours' value={this.state.signIn.hours} onChange={this.fnSignTimeHours} />
									<input type='text' className='form-control' placeholder='Minutes' value={this.state.signIn.minutes} onChange={this.fnSignTimeMinutes} />
								</div>
								<br />
								{/* <div className="card"> */}
								{/* <div className="card-body"> */}
								<div className='text-center'>
									<h5>Out - In</h5>
									{this.state.outIn != '' &&
										<table className='table table-bordered'>
											<thead>
												<tr>
													<td>Out</td>
													<td>In</td>
													<td></td>
												</tr>
											</thead>
											<tbody>
												{
													this.state.outIn.map((object, i) => {
														return (
															<tr key={i}>
																<td>
																	<div className='input-group'>
																		<input type='text' className='form-control' placeholder='Hours' value={object.out.hours} onChange={(e) => { this.handleEntryLog(e, i, 'out', 'hours') }} />
																		<input type='text' className='form-control' placeholder='Minutes' value={object.out.minutes} onChange={(e) => { this.handleEntryLog(e, i, 'out', 'minutes') }} />
																	</div>
																</td>
																<td>
																	<div className='input-group'>
																		<input type='text' className='form-control' placeholder='Hours' value={object.in.hours} onChange={(e) => { this.handleEntryLog(e, i, 'in', 'hours') }} />
																		<input type='text' className='form-control' placeholder='Minutes' value={object.in.minutes} onChange={(e) => { this.handleEntryLog(e, i, 'in', 'minutes') }} />
																	</div>
																</td>
																<td>
																	<button className='btn btn-outline-danger remove-border-radius' style={{height: '52px'}} onClick={(e) => { this.handleRemoveLog(e, i) }}>X</button>
																</td>
															</tr>
														);
													})}
											</tbody>
										</table>
									}
									<button className='btn btn-info text-center remove-border-radius' onClick={this.fnOutIn} disabled={!this.state.signIn.hours ? true : false || !this.state.signIn.minutes ? true : false}><strong>+</strong></button>&nbsp;
						</div>
								{/* </div> */}
								{/* </div> */}
								<br />

								{/* <div className='text-center'>
							<button className='btn btn-success' onClick={this.calculateOutInLogs} disabled={this.basicInputs()}>Submit</button>
						</div> */}

								<br />
								<br />
								<br />
							</div>
							<div className='col-md-6'>
								<br />
								<label>Time to Go Home</label>
								<div className='input-group'>
									<span className="border border-warning" style={{ padding: '30px', backgroundColor: '#f1f4f7', color: 'black' }}>
										{/* {this.state.goToHome.hours} : {this.state.goToHome.minutes} */}
										{!isNaN(goToHomeHM.hours) && !isNaN(goToHomeHM.minutes) &&
											<span>{goToHomeHM.hours} : {goToHomeHM.minutes}</span>
										}
									</span>
								</div>
								<br />

								<label>Your Working Hours</label>
								<div className='input-group'>
									<span className="border border-success" style={{ padding: '30px', backgroundColor: (this.state.WorkedTimeCompState && this.state.WorkedTimeCompState.hours >= this.state.workTime.hours && this.state.WorkedTimeCompState.minutes >= this.state.workTime.minutes) ? 'green' : '#f1f4f7', color: (this.state.WorkedTimeCompState && this.state.WorkedTimeCompState.hours >= this.state.workTime.hours && this.state.WorkedTimeCompState.minutes >= this.state.workTime.minutes) ? 'white' : 'black' }}>
										{!this.basicInputs() &&
											<WorkedTime home={this.state} getWorkTime={(work) => { this.setState({ WorkedTimeCompState: work }) }} />
										}
									</span>
								</div>

								<br />
								<br />
								<br />

							</div>


						</div>
					</div>
				</section>
			</div>
			</Menu>
			</React.Fragment>
		);
	}
}

export default Home;