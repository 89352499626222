import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Menu from '../../../LeftPanel';
import { changePassword } from '../../../../services/handler';

class ChangePassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      inputErrors: {},
      loader: false,
    }
  }

  _handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value
    }, this._validateFormInput(name, value));
  }

  _validateFormInput = (field, value) => {
    const {
      newPassword,
      confirmNewPassword,
      inputErrors
    } = this.state;

    // Extra rules
    let displayName = '';
    let maxLength = null;

    if (field === 'currentPassword') {
      displayName = 'Current Password'
      maxLength = 16;
    } else if (field === 'newPassword') {
      displayName = 'New Password'
      maxLength = 16;
    } else if (field === 'confirmNewPassword') {
      displayName = 'Confirm New Password'
      maxLength = 16;
    }

    // * Required
    // * Max Length
    // * Regex
    if (['currentPassword', 'newPassword'].includes(field)) {
      if (value === '') {
        inputErrors[field] = { message: displayName + ' is required' }
      } else if (maxLength && (value.length > maxLength)) {
        inputErrors[field] = { message: displayName + ' can be at most ' + maxLength + ' characters' }
      } else {
        delete inputErrors[field]
      }
    }

    // * Did not match
    if (['newPassword'].includes(field)) {
      if ((confirmNewPassword !== '') && (value !== confirmNewPassword)) {
        inputErrors['confirmNewPassword'] = { message: 'Confirm New Password' + ' did\'n match with new password ' }
      } else if (confirmNewPassword === value) {
        delete inputErrors['confirmNewPassword']
      }
    }
    if (['confirmNewPassword'].includes(field)) {
      if (value !== newPassword) {
        inputErrors[field] = { message: displayName + ' did\'n match with new password ' }
      } else {
        delete inputErrors[field]
      }
    }

    this.setState({
      inputErrors
    });
  }

  _submitChangePassword = (e) => {
    e.preventDefault();
    const {
      currentPassword,
      newPassword,
      confirmNewPassword,
      inputErrors,
      loading,
    } = this.state;

    // Validate inputs
    this._validateFormInput('currentPassword', currentPassword);
    this._validateFormInput('newPassword', newPassword);
    this._validateFormInput('confirmNewPassword', confirmNewPassword);

    if (_.isEmpty(inputErrors) && !loading) {
      const requestData = {
        currentPassword: currentPassword.trim(),
        newPassword: newPassword.trim(),
        confirmNewPassword: confirmNewPassword.trim(),
      };

      this.setState({
        loading: true
      }, () => {
        // ~~~ Change Password ~~~
        changePassword(requestData)
          .then(res => {
            if (res.success) {
              toast.success("Password changed sucessfully !");
              this.props.history.push('/settings');
            }
          })
          .catch(error => {
            this.setState({
              loading: false
            });
            toast.error(error.error);            
          });
      })

    }
  }

  render() {
    const {
      currentPassword,
      newPassword,
      confirmNewPassword,
      inputErrors,
      loading,
    } = this.state;

    return (
      <Fragment>
        <Menu>
          <div id="colorlib-main">
            <section className="ftco-section">
              <div className="container">
                <h1>Change Password</h1>
                <hr />
                <div className="row">
                  <div className="col-6">
                    <form onSubmit={this._submitChangePassword} className="bg-light p-5 contact-form">
                      <div className="form-group">
                        <label>Current Password</label>
                        <input type="password" name="currentPassword" className="form-control" value={currentPassword} onChange={this._handleInputChange} />
                        {inputErrors.currentPassword ? <p className="col-red">{inputErrors.currentPassword.message}</p> : ''}
                      </div>
                      <div className="form-group">
                        <label>New Password</label>
                        <input type="password" name="newPassword" className="form-control" value={newPassword} onChange={this._handleInputChange} />
                        {inputErrors.newPassword ? <p className="col-red">{inputErrors.newPassword.message}</p> : ''}
                      </div>
                      <div className="form-group">
                        <label>Confirm New Password</label>
                        <input type="password" name="confirmNewPassword" className="form-control" value={confirmNewPassword} onChange={this._handleInputChange} />
                        {inputErrors.confirmNewPassword ? <p className="col-red">{inputErrors.confirmNewPassword.message}</p> : ''}
                      </div>
                      <div className="form-group">
                        <button type="submit" className="btn btn-success remove-border-radius mr5">{loading ? (<i className="fa fa-spinner fa-spin"></i>) : 'Update Password'}</button>
                        {!loading && <Link to={'/settings'} className="btn btn-light remove-border-radius">Close</Link>}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Menu>
      </Fragment>
    )
  }
}

export default ChangePassword