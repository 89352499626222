import React, { Component } from 'react';

class WorkedTime extends Component {
    constructor() {
        super();

        this.state = {
            currentTime: new Date().toLocaleTimeString(),
            work: {
                hours: '',
                minutes: '',
                seconds: ''
            }
        }

        this.returnTotalMinutes = this.returnTotalMinutes.bind(this);
    }

    componentDidMount() {
        this.intervalId = setInterval(this.timer.bind(this), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    returnTotalMinutes(hours, minutes) {
        return parseInt(hours * 60) + parseInt(minutes);
    }

    timer() {
        var signInMinutes = this.returnTotalMinutes(this.props.home.signIn.hours, this.props.home.signIn.minutes);
        var now = new Date();
        var nowMinutes = this.returnTotalMinutes(now.getHours(), now.getMinutes());
        var outInMinutes = 0;
        for (let value of this.props.home.outIn) {
            let outMinutes = this.returnTotalMinutes(value.out.hours, value.out.minutes);
            let inMinutes = value.out.hours > value.in.hours ? this.returnTotalMinutes(value.in.hours + 12, value.in.minutes) : this.returnTotalMinutes(value.in.hours, value.in.minutes);
            outInMinutes += inMinutes - outMinutes;
        };
        var workedMinutes = parseInt(nowMinutes - signInMinutes - outInMinutes);
        this.setState({
            work: {
                hours: parseInt(workedMinutes / 60),
                minutes: parseInt(workedMinutes % 60),
                seconds: now.getSeconds()
            }
        });
        this.props.getWorkTime(this.state.work);
    }

    render() {
        return (
            <div>
                {this.state.work.hours} : {this.state.work.minutes} : {this.state.work.seconds}
            </div>
        );
    }

}

export default WorkedTime;