import { API } from './api';

// SIGN UP
export async function signUp(data) {
  return new Promise((resolve, reject) => {
    return API('POST', 'user/add', data).then(response => {
      response.data.success ? resolve(response) : reject(response);
      resolve(response)
    }).catch(error => {
      reject(error);
    });
  });
}

// LOGIN
export async function login(data) {
  return new Promise((resolve, reject) => {
    return API('POST', 'user/login', data).then(response => {
      response.data.success ? resolve(response) : reject(response);
      resolve(response)
    }).catch(error => {
      reject(error);
    });
  });
}

/***********************************************************
  TODO
************************************************************/

// GET TODO
export async function getToDoItems() {
  return new Promise((resolve, reject) => {
    return API('GET', 'user/todo', null).then(response => {
      response.data.success ? resolve(response.data) : reject(response.data);
    }).catch(error => {
      reject(error);
    });
  });
}

// ADD TODO
export async function addToDoItem(data) {
  return new Promise((resolve, reject) => {
    return API('POST', 'user/todo/add', data).then(response => {
      response.data.success ? resolve(response.data) : reject(response.data);
    }).catch(error => {
      reject(error);
    });
  });
}

// UPDATE TODO ITEM
export async function updateToDoItem(data, itemId) {
  return new Promise((resolve, reject) => {
    return API('PUT', 'user/todo/update/' + itemId, data).then(response => {
      response.data.success ? resolve(response.data) : reject(response.data);
    }).catch(error => {
      reject(error);
    });
  });
}

// DELETE TODO ITEM
export async function deleteToDoItem(itemId) {
  return new Promise((resolve, reject) => {
    return API('delete', 'user/todo/delete/' + itemId, null).then(response => {
      response.data.success ? resolve(response.data) : reject(response.data);
    }).catch(error => {
      reject(error);
    });
  });
}

// UPDATE TODO ITEM STATUS
export async function updateToDoItemStatus(itemId) {
  return new Promise((resolve, reject) => {
    return API('PUT', 'user/todo/update-status/' + itemId, null).then(response => {
      response.data.success ? resolve(response.data) : reject(response.data);
    }).catch(error => {
      reject(error);
    });
  });
}

/***********************************************************
  CATEGORY
************************************************************/

// GET CATEGORY
export async function getCategory() {
  return new Promise((resolve, reject) => {
    return API('GET', 'user/todo/category', null).then(response => {
      response.data.success ? resolve(response.data) : reject(response.data);
    }).catch(error => {
      reject(error);
    });
  });
}

// ADD CATEGORY
export async function addCategory(data) {
  return new Promise((resolve, reject) => {
    return API('POST', 'user/todo/category/add', data).then(response => {
      response.data.success ? resolve(response.data) : reject(response.data);
    }).catch(error => {
      reject(error);
    });
  });
}

// UPDATE CATEGORY
export async function updateCategory(data, itemId) {
  return new Promise((resolve, reject) => {
    return API('PUT', 'user/todo/category/update/' + itemId, data).then(response => {
      response.data.success ? resolve(response.data) : reject(response.data);
    }).catch(error => {
      reject(error);
    });
  });
}

// DELETE CATEGORY
export async function deleteCategory(itemId) {
  return new Promise((resolve, reject) => {
    return API('delete', 'user/todo/category/delete/' + itemId, null).then(response => {
      response.data.success ? resolve(response.data) : reject(response.data);
    }).catch(error => {
      reject(error);
    });
  });
}

/***********************************************************
  ACCOUNT
************************************************************/

// Get Profile Details
export async function profileDetails() {
  return new Promise((resolve, reject) => {
    return API('GET', 'user/account/profile-details', null).then(response => {
      response.data.success ? resolve(response.data) : reject(response.data);
    }).catch(error => {
      reject(error);
    });
  });
}

// Update Profile Details
export async function profileUpdate(data) {
  return new Promise((resolve, reject) => {
    return API('POST', 'user/account/profile-update', data).then(response => {
      response.data.success ? resolve(response.data) : reject(response.data);
    }).catch(error => {
      reject(error);
    });
  });
}

// Change Password
export async function changePassword(data) {
  return new Promise((resolve, reject) => {
    return API('POST', 'user/account/change-password', data).then(response => {
      response.data.success ? resolve(response.data) : reject(response.data);
    }).catch(error => {
      reject(error);
    });
  });
}