import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import Menu from '../../LeftPanel';
import { login } from '../../../services/adminHandler';

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userName: '',
      password: '',
      adminToken: localStorage.getItem('adminToken'),
      inputErrors: {}
    }
  }

  _handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value
    }, this._validateFormInput(name, value));
  }

  _doLogin = (e) => {
    e.preventDefault();
    const {
      userName,
      password,
      inputErrors,
    } = this.state;

    // Validate inputs
    this._validateFormInput('userName', userName);
    this._validateFormInput('password', password);

    if (_.isEmpty(inputErrors)) {
      const requestData = {
        userName: userName.trim(),
        password: password.trim()
      };
      // ~~~ Login ~~~
      login(requestData)
        .then(res => {
          if (res.data.success) {
            localStorage.setItem('adminToken', res.data.adminToken);
            this.setState({
              adminToken: localStorage.getItem('adminToken')
            })
          } else {
            alert(res.data.error);
          }
        }
        ).catch(error => {
          alert(error.data.error)
        });
    }
  }


  _validateFormInput = (field, value) => {
    const {
      inputErrors
    } = this.state;

    // Extra rules
    let displayName = '';
    let maxLength = null;

    if (field === 'userName') {
      displayName = 'User Name'
      maxLength = 100;
    } else if (field === 'password') {
      displayName = 'Password'
      maxLength = 16;
    }

    // * Required
    // * Max Length
    // * Regex
    if (['userName', 'password'].includes(field)) {
      if (value === '') {
        inputErrors[field] = { message: displayName + ' is required' }
      } else if (maxLength && (value.length > maxLength)) {
        inputErrors[field] = { message: displayName + ' can be at most ' + maxLength + ' characters' }
      } else {
        delete inputErrors[field]
      }
    }

    this.setState({
      inputErrors
    });
  }

  render() {

    const {
      userName,
      password,
      adminToken,
      inputErrors
    } = this.state;

    if (adminToken) {
      return <Redirect to={'/admin'} />
    }

    return (
      <Fragment>
        <Menu>
          <div id="colorlib-main">
            <section className="ftco-section">
              <div className="container">
                <div className="col-md-6 d-flex">
                  <form className="bg-light p-5 contact-form" onSubmit={this._doLogin}>
                    <p className="text-center">Login as Administrator</p>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="User Name" name="userName" value={userName} onChange={this._handleInputChange} />
                      {inputErrors.userName ? <p className="col-red">{inputErrors.userName.message}</p> : ''}
                    </div>
                    <div className="form-group">
                      <input type="password" className="form-control" placeholder="Password" name="password" value={password} onChange={this._handleInputChange} />
                      {inputErrors.password ? <p className="col-red">{inputErrors.password.message}</p> : ''}
                    </div>
                    <div className="form-group text-center">
                      <input type="submit" value={'Log In'} className="btn btn-info py-3 px-5" />
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </Menu>
      </Fragment>
    )
  }
}

export default Login