import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// CUSTOM Components
import Login from './components/Login';
import Signup from './components/Signup';
import Welcome from './components/Welcome'
import WorkClock from './components/WorkClock/WorkClock';
import ToDo from './components/ToDo';
import Settings from './components/Settings';
import EditProfile from './components/Settings/Account/EditProfile';
import ChangePassword from './components/Settings/Account/ChangePassword';
import ToDoCategory from './components/ToDoCategory';
import PrivateRoute from './components/PrivateRoute';
import NotFoundPage from './components/NotFoundPage';
import ErrorServerDown from './components/ErrorServerDown';

// CUSTOM ADMIN Components
import AdminLogin from './components/Admin/Login';
import AdminRoute from './components/AdminRoute';
import AdminDashboard from './components/Admin/Dashboard';
import UserList from './components/Admin/UserList';
import UserLog from './components/Admin/UserLog';

// CUSTOM Css
import './assets/css/globalStyle.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.min.css';

class App extends Component {

  render() {

    return (
      <Router>
        <Fragment>
          <ToastContainer />
          <Switch>

            {/***** Public Routes *****/}
            <Route exact path={'/login'} component={Login} />
            <Route exact path={'/signup'} component={Signup} />
            <Route exact path={'/logout'} component={() => {localStorage.removeItem('token');window.location.href='/login'}} />
            
            {/***** Authenticated / Private Routes *****/}
            <PrivateRoute exact path={'/'} component={Welcome} />
            <PrivateRoute exact path={'/work-clock'} component={WorkClock} />
            <PrivateRoute exact path='/to-do' component={ToDo} />
            <PrivateRoute exact path='/settings' component={Settings} />
            {/* <PrivateRoute exact path='/settings/to-do/category' component={ToDoCategory} /> */}
            <PrivateRoute exact path='/settings/account/edit-profile' component={EditProfile} />
            <PrivateRoute exact path='/settings/account/change-password' component={ChangePassword} />

            {/***** Admin Routes *****/}
            <Route exact path='/login-as-admin' component={AdminLogin} />
            <AdminRoute exact path='/admin' component={AdminDashboard} />
            <AdminRoute exact path='/users-list' component={UserList} />
            <AdminRoute exact path='/users-logs' component={UserLog} />

            <Route exact path='/500' component={ErrorServerDown} />            
            <PrivateRoute exact path='*' component={NotFoundPage} />            

          </Switch>
        </Fragment>
      </Router>
    );
  }
}

export default App;
