import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

class Menus extends Component {
  constructor(props) {
    super(props);
    const { pathname } = this.props.location
    this.state = {
      activeMenu: pathname,
    }
  }

  _handleMenuClick = (menuName) => {
    this.setState({
      activeMenu: menuName,
    })
  }

  _logoutAsAdmin = () => {
    localStorage.removeItem('adminToken');
    this.props.history.push('/login-as-admin');    
  }

  render() { 
    const {
      activeMenu,
    } = this.state;   
    return (
      <Fragment>
        <h2>
          <span className="badge badge-secondary remove-border-radius mr5">Admin Area</span>
          <Link className="btn btn-light mr5 remove-border-radius" to={'/'}><i className="fa fa-times" aria-hidden="true"></i></Link>
          <button className="btn btn-light remove-border-radius" onClick={this._logoutAsAdmin}><i className="fa fa-sign-out" aria-hidden="true"></i></button>
        </h2>
        <Link className={"remove-border-radius mr5 " + (activeMenu === '/admin' ? "btn btn-info" : 'btn btn-primary')} to={'/admin'} onClick={() => this._handleMenuClick('/admin')}><i className="fa fa-tachometer" aria-hidden="true"></i></Link>
        <Link className={"remove-border-radius mr5 " + (activeMenu === '/users-list' ? "btn btn-info" : 'btn btn-primary')} to={'/users-list'} onClick={() => this._handleMenuClick('/users-list')}>Users List</Link>
        <Link className={"remove-border-radius mr5 " + (activeMenu === '/users-logs' ? "btn btn-info" : 'btn btn-primary')} to={'/users-logs'} onClick={() => this._handleMenuClick('/users-logs')}>Users Logs</Link>
        <hr />        
      </Fragment>
    )
  }
}

export default withRouter(Menus)