import React, { Component, Fragment } from 'react';
import {
  getUsersLogs,
} from './../../../services/adminHandler';

import Menus from '../Menus';

class UserLog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      usersLogs: [],
      loading: true
    }
  }

  componentWillMount() {
    // ~~~ Get Users Logs ~~~
    getUsersLogs()
      .then(res => {
        this.setState({
          usersLogs: res.userLog,
          loading: false
        });
      }
      ).catch(error => {
        console.log(error)
      });
  }

  render() {
    const {
      usersLogs,
      loading
    } = this.state;
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return (
      <Fragment>
        <div className="container-fluid">          
          <Menus />            
          <div className="row">
            <div className="col">
              <h4 className="alert alert-info remove-border-radius text-center">Users Logs</h4>
              {usersLogs.length ? (
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">IP Address</th>
                    <th scope="col">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {usersLogs.map((value, index) => {
                    return (
                      <tr key={value._id}>
                        <th scope="row">{index + 1}</th>
                        <td>{value.user_id.name}</td>
                        <td>{value.user_id.email}</td>
                        <td>{value.ip_address}</td>
                        <td>{new Date(value.created_at).toLocaleString("en-US", options)}</td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </table>
              ) : (
                  loading ? (
                    <h3 className="text-center">Loading...</h3>
                  ) : (
                      <div>
                        <h5 className="text-center">No records found !</h5>
                      </div>
                    )
                )
              }
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default UserLog