import React, { Component, Fragment } from 'react';
import {
  getTodayUsersLogs,
} from './../../../services/adminHandler';

import Menus from '../Menus';

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      todayUsersLogs: []
    }
  }

  componentWillMount() {
    // ~~~ Get Users Logs ~~~
    getTodayUsersLogs()
      .then(res => {
        this.setState({
          todayUsersLogs: res.userLog
        });
      }
      ).catch(error => {
        console.log(error)
      });
  }

  render() {
    const {
      todayUsersLogs
    } = this.state;
    var options = {hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return (
      <Fragment>
        <div className="container-fluid">
          <Menus />
          <div className="row">
            <div className="col">
              <h4 className="alert alert-info remove-border-radius text-center">Admin Dashboard</h4>
              {/* <div className="text-center">
                <span><em>Awesome things are coming soon !</em></span>
              </div> */}
            </div>
          </div>

          <div className="row">
            <div className="col-6">

              <div className="card">
                <div className="card-header">
                  Today's Logged in Users are - <span className="badge badge-secondary">{todayUsersLogs.length}</span>
                </div>
                <div className="card-body">

                  {todayUsersLogs.length ? (
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Logged in time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {todayUsersLogs.map((value, index) => {
                          return (
                            <tr key={value._id}>
                              <th scope="row">{index + 1}</th>
                              <td>{value.user_id.name}</td>
                              <td>{value.user_id.email}</td>
                              <td>{new Date(value.created_at).toLocaleTimeString("en-US", options)}</td>
                            </tr>
                          )
                        })
                        }
                      </tbody>
                    </table>
                  ) : (
                      <div>
                        <h5 className="text-center">No records found !</h5>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>

        </div>
      </Fragment>
    )
  }
}

export default Dashboard