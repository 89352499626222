import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Menu from '../../../LeftPanel';
import { profileDetails, profileUpdate } from '../../../../services/handler';

class EditProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      inputErrors: {},
      loading: false,
      updating: false,
    }
  }

  componentWillMount() {
    // ~~~ Get Profile Details ~~~
    this.setState({
      loading: true
    }, () => {
      profileDetails()
        .then(res => {
          this.setState({
            profile: res.data,
            loading: false
          });
        }
        ).catch(error => {
          console.log(error)
        });
    })
  }

  _handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      profile: {
        ...this.state.profile,
        [name]: value
      }
    }, this._validateFormInput(name, value));
  }

  _validateFormInput = (field, value) => {
    const {
      inputErrors
    } = this.state;

    // Extra rules
    let displayName = '';
    let maxLength = null;

    if (field === 'name') {
      displayName = 'Name';
      maxLength = 50;
    }

    // * Required
    // * Max Length
    if (['name'].includes(field)) {
      if (value === '') {
        inputErrors[field] = { message: displayName + ' is required' }
      } else if (maxLength && (value.length > maxLength)) {
        inputErrors[field] = { message: displayName + ' can be at most ' + maxLength + ' characters' }
      } else {
        delete inputErrors[field]
      }
    }

    this.setState({
      inputErrors
    });
  }

  _submitUpdateProfile = (e) => {
    e.preventDefault();
    const {
      profile,
      inputErrors,
      updating,
    } = this.state;

    // Validate inputs
    this._validateFormInput('name', profile.name);

    if (_.isEmpty(inputErrors) && !updating) {
      this.setState({
        updating: true
      }, () => {
        // ~~~ Profile Update ~~~
        profileUpdate(profile)
          .then(res => {
            if (res.success) {
              toast.success("Profile updated sucessfully !");
              let authUserInfo = JSON.parse(localStorage.getItem('authUserInfo'));
              authUserInfo.name = profile.name;
              localStorage.setItem('authUserInfo', JSON.stringify(authUserInfo));
              this.props.history.push('/settings');              
            }
          })
          .catch(error => {
            this.setState({
              updating: false
            });
            alert(error.error)
          });
      })

    }
  }

  render() {
    const {
      profile,
      inputErrors,
      loading,
      updating,
    } = this.state;

    return (
      <Fragment>
        <Menu>
          <div id="colorlib-main">
            <section className="ftco-section">
              <div className="container">
                <h1>Edit Profile</h1>
                <hr />
                {loading ? (
                  <h3>Loading...</h3>
                ) : (
                    <div className="row">
                      <div className="col-6">                        
                        <form onSubmit={this._submitUpdateProfile} className="bg-light p-5 contact-form">
                          <div className="form-group">
                            <label>Name</label>
                            <input type="text" name="name" className="form-control" value={profile.name} onChange={this._handleInputChange} />
                            {inputErrors.name ? <p className="col-red">{inputErrors.name.message}</p> : ''}
                          </div>
                          <div className="form-group">
                            <label>Email</label>
                            <input type="text" name="newPassword" className="form-control" value={profile.email} disabled={true} />
                          </div>
                          <div className="form-group">
                            <button type="submit" className="btn btn-success remove-border-radius mr5">{updating ? (<i className="fa fa-spinner fa-spin"></i>) : 'Update Profile'}</button>
                            {!updating && <Link to={'/settings'} className="btn btn-light remove-border-radius">Close</Link>}
                          </div>
                        </form>
                      </div>
                    </div>

                  )}
              </div>
            </section>
          </div>
        </Menu>
      </Fragment>
    )
  }
}

export default EditProfile