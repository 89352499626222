import axios from 'axios';
import config from '../config';

export default function API(method, endPoint, data) {
  return axios({
    method: method,
    url: config.BASE_API_DOMAIN + '/' + endPoint,
    headers: { token: localStorage.getItem('token') },
    data: data
  })
    .then(function (response) {
      return response
    })
    .catch(error => {
      if (!error.response) {
        window.location.href = `/500`;
        return {
          data: {
            success: false,
            error: 'Network Error ! Please try after sometime.'
          }
        }
      } else if (error.response.data.error === 'Failed to authenticate token') {
        localStorage.removeItem('token')
        window.location.href = `/login`;
        return {
          data: {
            success: false,
            error: 'Session expired ! Please, Login again.'
          }
        }
      } else {
        return error.response
      }
    });
}

export { API };
