import React, { Component, Fragment } from 'react';
import {
  getUsersList,
} from './../../../services/adminHandler';

import Menus from '../Menus';

class UserList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      usersList: [],
      loading: true
    }
  }

  componentWillMount() {
    // ~~~ Get Users List ~~~
    getUsersList()
      .then(res => {
        this.setState({
          usersList: res.usersList,
          loading: false
        });
      }
      ).catch(error => {
        console.log(error)
      });
  }

  render() {
    const {
      usersList,
      loading
    } = this.state;
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return (
      <Fragment>
        <div className="container-fluid">
          <Menus />
          <div className="row">
            <div className="col">
              <h4 className="alert alert-info remove-border-radius text-center">Users List</h4>
              {usersList.length ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Status</th>
                      <th scope="col">Role</th>
                      <th scope="col">IP Address</th>
                      <th scope="col">Created At</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Last Logged In</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList.map((value, index) => {
                      return (
                        <tr key={value._id}>
                          <th scope="row">{index + 1}</th>
                          <td>{value.name}</td>
                          <td>{value.email}</td>
                          <td>{value.status ? 'Active' : 'Inactive'}</td>
                          <td>{value.admin ? 'Admin' : 'User'}</td>
                          <td>{value.ip_address}</td>
                          <td>{new Date(value.created_at).toLocaleString("en-US", options)}</td>
                          <td>{new Date(value.updated_at).toLocaleString("en-US", options)}</td>
                          <td>{value.last_login && new Date(value.last_login.created_at).toLocaleString("en-US", options)}</td>
                        </tr>
                      )
                    })
                    }
                  </tbody>
                </table>
              ) : (
                  loading ? (
                    <h3 className="text-center">Loading...</h3>
                  ) : (
                      <div>
                        <h5 className="text-center">No records found !</h5>
                      </div>
                    )
                )

              }

            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default UserList