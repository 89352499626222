import { AdminAPI } from './adminApi';

// LOGIN
export async function login(data) {
  return new Promise((resolve, reject) => {
    return AdminAPI('POST', 'user/login-as-admin', data).then(response => {
      response.data.success ? resolve(response) : reject(response);
      resolve(response)
    }).catch(error => {
      reject(error);
    });
  });
}

// GET USERS LIST
export async function getUsersList() {
  return new Promise((resolve, reject) => {
    return AdminAPI('GET', 'admin/user-list', null).then(response => {
      response.data.success ? resolve(response.data) : reject(response.data);
    }).catch(error => {
      reject(error);
    });
  });
}

// GET USERS LOGS
export async function getUsersLogs() {
  return new Promise((resolve, reject) => {
    return AdminAPI('GET', 'admin/user-log', null).then(response => {
      response.data.success ? resolve(response.data) : reject(response.data);
    }).catch(error => {
      reject(error);
    });
  });
}

// GET TODAY'S USERS LOGS
export async function getTodayUsersLogs() {
  return new Promise((resolve, reject) => {
    return AdminAPI('GET', 'admin/user-log/today', null).then(response => {
      response.data.success ? resolve(response.data) : reject(response.data);
    }).catch(error => {
      reject(error);
    });
  });
}