import React, { Component } from 'react';
import Menu from '../LeftPanel'

class Welcome extends Component {

  render() {
    const user = JSON.parse(localStorage.getItem('authUserInfo'))
    return (
      <React.Fragment>
      <Menu>
        <div id="colorlib-main">
          <section className="ftco-section">
            <div className="container">
              <h3>{user.name} {user.admin && <span className="badge badge-secondary">Admin</span>}</h3>
              <h5>{user.email}</h5>
              <hr />
            </div>
          </section>
        </div>
      </Menu>
      </React.Fragment>
    )
  }
}

export default Welcome