import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Menu from '../LeftPanel';

class Settings extends Component {

  render() {
    return (
      <Fragment>
        <Menu />
        <div id="colorlib-main">
          <section className="ftco-section">
            <div className="container">
              <h1>Settings</h1>
              <hr />
              
              <div className="row">
                <div className="col-2">
                  {/* ACCOUNT */}
                  <div className="dropdown">
                    <button className="btn btn-info dropdown-toggle remove-border-radius" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Account
                  </button>                    
                    <div className="dropdown-menu remove-border-radius" aria-labelledby="dropdownMenuButton">
                      <Link to={'/settings/account/edit-profile'} className="dropdown-item">Edit Profile</Link>
                      <Link to={'/settings/account/change-password'} className="dropdown-item">Change Password</Link>
                    </div>
                  </div>
                </div>
                {/* <div className="col-2">                  
                  <div className="dropdown">
                    <button className="btn btn-info dropdown-toggle remove-border-radius" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      To Do
                  </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <Link to={'/settings/to-do/category'} className="dropdown-item">Category</Link>
                    </div>
                  </div>
                </div> */}
              </div>



            </div>
          </section>
        </div>
      </Fragment>
    )
  }
}

export default Settings