import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { signUp } from './../../services/handler';

class Signup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      inputErrors: {},
      loading: false
    }
  }

  _handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value
    }, this._validateFormInput(name, value));
  }

  _submitNewRegistration = (e) => {
    e.preventDefault();
    const {
      name,
      email,
      password,
      confirmPassword,
      inputErrors,
      loading,
    } = this.state;

    // Validate inputs
    this._validateFormInput('name', name);
    this._validateFormInput('email', email);
    this._validateFormInput('password', password);
    this._validateFormInput('confirmPassword', confirmPassword);

    if (_.isEmpty(inputErrors) && !loading) {
      const requestData = {
        name: name.trim(),
        email: email.trim(),
        password: password.trim(),
        confirmPassword: confirmPassword.trim(),
      };

      this.setState({
        loading: true
      }, () => {
        // ~~~ Sign Up ~~~
        signUp(requestData)
          .then(res => {
            if (res.data.success) {
              toast.success('New user registered successfully !');
              this.props.history.replace('/login');
            } else {
              this.setState({
                loading: false
              });
              toast.error(res.data.error);              
            }
          }
          ).catch(error => {            
            if(error.data.inputErrors) {
              this.setState({
                loading: false,
                inputErrors: error.data.inputErrors.errors
              });
            } else {
              this.setState({
                loading: false
              });
              error.data.error && toast.error(error.data.error);
            }
          });
      })

    }
  }

  _validateFormInput = (field, value) => {
    const {
      password,
      confirmPassword,
      inputErrors
    } = this.state;

    // Extra rules
    let displayName = '';
    let maxLength = null;
    let regexCheck = null;

    if (field === 'name') {
      displayName = 'Name';
      maxLength = 50;
    } else if (field === 'email') {
      displayName = 'Email address'
      maxLength = 100;
      regexCheck = true;
    } else if (field === 'password') {
      displayName = 'Password'
      maxLength = 16;
    } else if (field === 'confirmPassword') {
      displayName = 'Confirm Password'
    }

    // * Required
    // * Max Length
    // * Regex
    if (['name', 'email', 'password'].includes(field)) {
      if (value === '') {
        inputErrors[field] = displayName + ' is required';
      } else if (maxLength && (value.length > maxLength)) {
        inputErrors[field] = displayName + ' can be at most ' + maxLength + ' characters';
      } else if (regexCheck) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(value)) {
          delete inputErrors[field]
        } else {
          inputErrors[field] = displayName + ' is invalid';
        }
      } else {
        delete inputErrors[field]
      }
    }

    // * Did not match
    if (['password'].includes(field)) {
      if (confirmPassword!=='' && value !== confirmPassword) {
        inputErrors['confirmPassword'] = 'Confirm Password did\'n match with password';
      } else {
        delete inputErrors['confirmPassword']
      }
    }
    if (['confirmPassword'].includes(field)) {
      if (value !== password) {
        inputErrors[field] = displayName + ' did\'n match with password';
      } else {
        delete inputErrors[field]
      }
    }

    this.setState({
      inputErrors
    });
  }

  render() {
    
    const {
      name,
      email,
      password,
      confirmPassword,
      inputErrors,
      loading,
    } = this.state;

    return (
      <div className="container">
        <div className="col-md-6 offset-md-3">
          <form onSubmit={this._submitNewRegistration} className="bg-light p-5 contact-form">
            <div className="form-group">
              <input type="text" name="name" className="form-control" placeholder="Name" value={name} onChange={this._handleInputChange} />
              {inputErrors.name ? <p className="col-red">{inputErrors.name}</p> : ''}
            </div>
            <div className="form-group">
              <input type="text" name="email" className="form-control" placeholder="Email" value={email} onChange={this._handleInputChange} />
              {inputErrors.email ? <p className="col-red">{inputErrors.email}</p> : ''}
            </div>
            <div className="form-group">
              <input type="password" name="password" className="form-control" placeholder="Password" value={password} onChange={this._handleInputChange} />
              {inputErrors.password ? <p className="col-red">{inputErrors.password}</p> : ''}
            </div>
            <div className="form-group">
              <input type="password" name="confirmPassword" className="form-control" placeholder="Confirm Password" value={confirmPassword} onChange={this._handleInputChange} />
              {inputErrors.confirmPassword ? <p className="col-red">{inputErrors.confirmPassword}</p> : ''}
            </div>
            <div className="form-group text-center">
              <button type="submit" className="btn btn-success py-3 px-5">{loading ? (<i className="fa fa-spinner fa-spin"></i>) : 'Sign Up'}</button>
            </div>
            {!loading &&
              <div className="form-group text-center">
                <Link to={'/login'} className="col-black">Login</Link>
              </div>
            }
          </form>
        </div>
      </div>
    )
  }
}

export default Signup