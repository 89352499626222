import React, { Component } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Menu from '../LeftPanel';
import {
  getToDoItems,
  addToDoItem,
  updateToDoItem,
  deleteToDoItem,
  updateToDoItemStatus,
} from './../../services/handler';

// Custom images
import WinkEmoji from '../../assets/images/Wink Emoji.png'
import iconEdit from '../../assets/images/edit.svg'
import iconDelete from '../../assets/images/delete.svg'

class ToDo extends Component {

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      newItem: '',
      inputError: {},
      editingItem: '',
      loading: true,
      saving: false,
      updating: false,
    }

    this.setWrapperRefOfEditToDoForm = this.setWrapperRefOfEditToDoForm.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillMount() {
    // ~~~ Get ToDo ~~~
    getToDoItems()
      .then(res => {
        this.setState({
          items: res.todo,
          loading: false
        });
      }
      ).catch(error => {
        alert(error.error);
        this.setState({
          loading: false
        });
      });
  }

  componentDidMount() {
    this.newItemInput.focus();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate() {
    if (this.state.editingItem !== '') {
      this.editingItemInputTitle.focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  _handleInputChange = (e) => {
    const {
      items
    } = this.state;
    let name = e.target.name;
    let value = e.target.value;
    let inputError = {};

    // Duplicate Validations
    if (this._findDuplicateItem(value.trim(), items)) {
      inputError = {
        title: 'Item already existed.'
      };
    }

    this.setState({
      [name]: value,
      inputError
    });
  }

  _submitTodoItem = (e) => {
    e.preventDefault();
    const {
      newItem,
      items,
      saving
    } = this.state;

    if (newItem.trim() && !saving) {
      const data = {
        title: newItem.trim(),
      };
      this.setState({
        saving: true
      }, this._saveItem(data));
    }
  }

  _saveItem = (data) => {
    const {
      items
    } = this.state;
    // ~~~ Add ToDo ~~~
    addToDoItem(data)
      .then(res => {
        toast.success("Saved successfully");
        items.push(res.newToDo);
        this.setState({
          items: items,
          newItem: '',
          saving: false
        });
        this.newItemInput.focus();
      })
      .catch(error => {
        this.setState({
          saving: false,
          inputError: error.errors
        });
      })
  }

  _findDuplicateItem = (item, data, avoidIndex = undefined) => {
    let flag = false;
    data.forEach((value, index) => {
      if ((value.title === item) && (avoidIndex !== index)) {
        flag = true;
        return;
      }
    });
    return flag;
  }

  _deleteItem = (index) => {
    if (window.confirm('Are you sure to Delete?')) {
      let items = this.state.items;
      // ~~~ Delete ToDo ~~~
      deleteToDoItem(items[index]._id)
        .then(res => {
          toast.success("Deleted successfully");
          items.splice(index, 1);
          this.setState({
            items: items
          });
        }
        ).catch(error => {
          console.log(error)
        });
    }
  }

  _editItem = (index) => {
    const editData = this.state.items;
    this.setState({
      editingItem: {
        index: index,
        data: editData[index]
      }
    });
  }

  _handleEditInputChange = (e) => {
    const {
      editingItem,
      items
    } = this.state;

    let name = e.target.name;
    let value = e.target.value;
    let inputError = {};

    if (this._findDuplicateItem(value.trim(), items, editingItem.index)) {
      inputError = {
        error: 'Item already existed.'
      };
    }

    this.setState({
      editingItem: {
        ...this.state.editingItem,
        data: {
          ...this.state.editingItem.data,
          [name]: value
        },
        inputError
      }
    });
  }

  _handleItemStatus = (index) => {
    let items = this.state.items;
    if(!items[index]['updating']) {
      items[index].updating = true;
      this.setState({
        items
      }, () => {
        // ~~~ Update ToDo Item Status ~~~
        updateToDoItemStatus(items[index]._id)
          .then(res => {
            items[index].status = !items[index].status;          
            delete items[index]['updating'];
            this.setState({
              items
            });
          }
          ).catch(error => {
            console.log(error)
          });
      })
    }
  }

  _updateEditingItem = (index, e) => {
    e.preventDefault();
    const {
      updating,
      editingItem,
      items,
    } = this.state;

    if (!updating) {
      this.setState({
        updating: true
      }, () => {
        // ~~~ Update ToDo Item ~~~
        const { data } = editingItem;
        updateToDoItem(data, data._id)
          .then(res => {
            toast.success("Updated successfully");
            const items = this.state.items;
            items[index] = res.updatedItem;
            this.setState({
              items,
              editingItem: '',
              updating: false
            });
          }
          ).catch(error => {
            this.setState({
              updating: false
            });
            console.log(error)
          });
      })
    }
  }

  _cancelEditingItem = () => {
    this.setState({
      editingItem: ''
    });
  }

  _handleEditToDoKeyDown = (e) => {
    if (e.key === 'Escape') {
      this._cancelEditingItem();
    }
  }

  setWrapperRefOfEditToDoForm(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this._cancelEditingItem();
    }
  }

  render() {

    const {
      items,
      newItem,
      inputError,
      editingItem,
      loading,
      saving,
      updating,
    } = this.state;
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

    return (
      <React.Fragment>
        <Menu>
          <div id="colorlib-main">
            <section className="ftco-section">
              <div className="container">
                <form onSubmit={this._submitTodoItem} className="bg-light p-4">
                  <div className="input-group">
                    <input type="text" autoComplete="off" ref={(input) => { this.newItemInput = input; }} name="newItem" className="form-control" placeholder="Enter item" disabled={saving} value={newItem} onChange={this._handleInputChange} />
                    <button type="submit" className="btn btn-success remove-border-radius btn-add-new-item" disabled={newItem === '' || !_.isEmpty(inputError)}>{saving ? (<i className="fa fa-spinner fa-spin"></i>) : 'Add'}</button>
                  </div>
                  {inputError.title &&
                    <div className="alert alert-danger remove-border-radius mt-1" role="alert">
                      {inputError.title}
                    </div>
                  }
                </form>
                <br />
                {items.length ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Items</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((value, index) => {
                        return (
                          <tr key={index} className={`tableRowHoverHandPointer ${value.status == 1 ? 'toDoItemDoneBgColor' : 'toDoItemBgColor'}`}>
                            <td>
                              {!value.updating ? (
                              <input type="checkbox" className="" onChange={() => this._handleItemStatus(index)} checked={value.status} />
                              ) : (
                                <i className="fa fa-spinner fa-spin"></i>
                              )}
                            </td>
                            <td className="txtColorBlack" title={new Date(value.created_at).toLocaleString("en-US", options)}>
                              {value.status == 1 ? (
                                <span onClick={() => this._handleItemStatus(index)}><del><i>{value.title}</i></del></span>
                              ) : (
                                  <span onClick={() => this._handleItemStatus(index)}>{value.title}</span>
                                )}

                              {editingItem !== '' && editingItem.index === index &&
                                <div className="card remove-border-radius" ref={this.setWrapperRefOfEditToDoForm}>
                                  <div className="card-body">
                                    <form onSubmit={(e) => this._updateEditingItem(index, e)} onKeyDown={(e) => this._handleEditToDoKeyDown(e)}>
                                      <div className="form-group">
                                        <input type="text" name="title" className="form-control" ref={(input) => { this.editingItemInputTitle = input; }} value={editingItem.data.title} disabled={updating} onChange={this._handleEditInputChange} />
                                        { editingItem.inputError && Object.keys(editingItem.inputError).length !== 0 &&                                           
                                          <div className="alert alert-danger remove-border-radius mt-1" role="alert">
                                            {editingItem.inputError.error}
                                          </div>
                                        }
                                        
                                      </div>
                                      <button type="submit" className="btn btn-info remove-border-radius mr5" disabled={!_.isEmpty(editingItem.inputError)}>{updating ? (<i className="fa fa-spinner fa-spin"></i>) : 'Update'}</button>
                                      {!updating && <button className="btn btn-default remove-border-radius" onClick={() => this._cancelEditingItem()}>Cancel</button>}
                                    </form>
                                  </div>
                                </div>}

                            </td>
                            <td className="actionTD">
                            {!value.updating && (
                              <React.Fragment>
                                <img src={iconEdit} className="editItemIcon mr5" onClick={() => this._editItem(index)} alt={'Edit'} />
                                <img src={iconDelete} className="deleteItemIcon" onClick={() => this._deleteItem(index)} alt={'Delete'} />
                              </React.Fragment>
                            )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                ) : (
                    loading ? (
                      <React.Fragment>
                        <h3 className="text-center">
                          <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        </h3>
                      </React.Fragment>
                    ) : (
                        <div className="kt-text-align-center">
                          <h5><em>Nothing to do ! <br />Enjoy your time</em></h5>
                          <img src={WinkEmoji} className="kt-width-25-percent" alt="Wink Emoji" />
                        </div>
                      )
                  )
                }
              </div>
            </section>
          </div>
        </Menu>
      </React.Fragment>
    )
  }
}

export default ToDo