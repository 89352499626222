import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

class ErrorServerDown extends Component {

  handleBack = () => {
    this.props.history.goBack();    
  }
  
  render() {   
    return (
      <div id="colorlib-main">
        <section className="ftco-section">
          <div className="container">
            <h1>500 - Server Down !</h1>
            <p>Try after sometime !</p>
            <button className="btn btn-info remove-border-radius mr5" onClick={this.handleBack}>Try Again</button>  
            <Link to={'/'} className="btn btn-light remove-border-radius">Go Home</Link>  
          </div>
        </section>
      </div>
    )
  }
}

export default withRouter(ErrorServerDown)